<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <template v-if="showRedirectCard">
                <v-card>
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Sign up</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p class="mb-0">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            Redirecting to <a :href="signupURL">the sign up form</a>...
                        </p>
                    </v-card-text>
                </v-card>
                <p class="mt-6 grey--text text--darken-2 text-center">Already have an account? <router-link :to="{ name: 'login', query: { email: this.$route.query.email } }">Sign in</router-link></p>
            </template>
        </v-col>
    </v-row>
</template>

<style>
</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
    },
    data: () => ({
        showRedirectCard: false,
    }),
    computed: {
        ...mapState({
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        signupURL() {
            // NOTE: the signup website URL should be to our unicorn springs signup page
            const websiteURL = new URL(process.env.VUE_APP_SIGNUP_WEBSITE_URL ?? 'https://unicornsprings.com');
            if (this.$route.query.email) {
                websiteURL.searchParams.set('email', this.$route.query.email);
            }
            return websiteURL.toString();
        },
    },
    watch: {
        focus() {
            this.redirect();
        },
    },
    methods: {
        redirect() {
            // show a link after 2 seconds in case auto-redirect fails or takes a long time
            setTimeout(() => {
                this.showRedirectCard = true;
            }, 2000);
            // use replace so that when user taps 'back' button from there, they won't
            // end up being redirected again to where they just wanted to come back from
            if (typeof window.location.replace === 'function') {
                window.location.replace(this.signupURL);
            } else {
                window.location.href = this.signupURL;
            }
        },
    },
    mounted() {
        this.redirect();
    },
};
</script>
